function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false
  };

  const opt = {
    ...defaultOptions,
    ...options
  };

  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map(locale => {
    const trimmedLocale = locale.trim();

    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
}

let locale = null;
function detectLocale() {
  if (locale) return locale;
  else {
    const locales = getBrowserLocales();
    if (locales) locale = locales[0];
    console.debug(`detected locale: ${locale}`);
    return locale;
  }
}
module.exports = detectLocale;
