<template>
  <v-container
    fluid
    class="fill-height pa-0"
  >
    <div class="count-container">
      <div
        class="dial-section"
        ref="dialSection"
      >
        <v-progress-circular
          rotate="-90"
          :size="dialSize - 40"
          :width="dialWidth"
          :value="percentage"
          :color="dialColor"
          ref="dial"
        >
          <div
            style="z-index:1"
            :style="{ fontSize: `${Math.floor(dialSize / 3)}px` }"
          >
            {{ $config.loaded ? occupancy : "--" }}
          </div>
        </v-progress-circular>
      </div>
      <div class="max-section">
        <div
          class="text-uppercase py-2 text-center"
          :style="{ fontSize: `${Math.floor(dialSize / 5)}px` }"
        >
          max {{ $config.loaded ? max : "--" }}
        </div>
      </div>
      <div
        class="buttons-section"
        ref="btnSection"
        :style="{
          'flex-direction':
            (verticalButtons ? 'column' : 'row') +
            ($config.reverseButtons ? '-reverse' : '')
        }"
      >
        <div>
          <v-btn
            fab
            :disabled="disableBtn"
            :height="btnSize - 20"
            :width="btnSize - 20"
            @click.stop="plus"
            class="count-button no-focus ma-2"
          >
            <v-icon :size="btnSize / 2">fa-plus</v-icon>
          </v-btn>
        </div>
        <div>
          <v-btn
            fab
            :disabled="disableBtn"
            :height="btnSize - 20"
            :width="btnSize - 20"
            @click.stop="minus"
            class="count-button no-focus ma-2"
          >
            <v-icon :size="btnSize / 2">fa-minus</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import sleepMixin from "../sleep-mixin";
import { ScreenOrientation } from "@capawesome/capacitor-screen-orientation"
import { NativeAudio } from '@capacitor-community/native-audio'
import { Haptics } from '@capacitor/haptics';

NativeAudio.preload({
  assetId: "plus",
  assetPath: "plus.wav",
  audioChannelNum: 1,
  isUrl: false
});

NativeAudio.preload({
  assetId: "minus",
  assetPath: "minus.wav",
  audioChannelNum: 1,
  isUrl: false
});

export default {
  mixins: [sleepMixin],
  data: () => ({
    dialSize: 100,
    btnSize: 50,
    reloading: false
  }),
  watch: {
    treshold(val) {
      const dial = this.$refs.dial;
      if (dial) {
        const svg = dial.$el.children[0];
        svg.children[0].style.fill = val === 3 ? "#F44336" : "transparent";
      }
    },
    "$parent.$parent.$parent.showFooter"() {
      if (this.onResize) this.onResize();
    }
  },
  computed: {
    connected() {
      return this.$config.serverConnected;
    },
    max() {
      return this.$config.site.max;
    },
    occupancy() {
      return this.$config.site.occupancy;
    },
    percentage() {
      if (!this.max) return 0;
      return (this.occupancy / this.max) * 100;
    },
    treshold() {
      const {
        $config: {
          site: { tresholds }
        },
        percentage
      } = this;
      for (var i = 0; i < tresholds.length; i++) {
        if (percentage < tresholds[i]) return i;
      }
      return tresholds.length;
    },
    dialWidth() {
      let width = this.dialSize / 20;
      width = Math.min(width, 20);
      return Math.floor(width);
    },
    dialColor() {
      if (!this.connected) return "#ffffff";
      else {
        switch (this.treshold) {
          case 0:
            return "#4CAF50"; // green
          case 1:
            return "#FFC107"; // amber
          case 2:
            return "#FF9800"; // orange
          default:
            return "#ffffff";
        }
      }
    },
    verticalButtons() {
      return !!this.$config.verticalButtons;
    },
    disableBtn() {
      return !this.connected;
    }
  },
  methods: {
    async minus() {
      this.confirmButtonPress(true);
      this.checkFeedback();
      this.minusIO.writeIO(true);
    },
    async plus() {
      this.confirmButtonPress(false);
      this.checkFeedback();
      this.plusIO.writeIO(true);
    },
    checkFeedback() {
      const promise = new Promise((resolve, reject) => {
        let unwatch;
        const rejectTimeout = setTimeout(() => {
          unwatch.call(this);
          reject();
        }, 5000);
        unwatch = this.$watch("occupancy", () => {
          clearTimeout(rejectTimeout);
          unwatch.call(this);
          resolve();
        });
      });
      promise.catch(async () => {
        this.reloading = true;
        console.debug(`Occupancy didn't changed, reloading server`);
        await this.$config.reloadServer();
        this.load();
        console.debug(`server reloaded`);
        this.reloading = false;
      });
    },
    confirmButtonPress(minus = false) {
      if (this.$config.vibrations) {
        // FIXME: not working on ios
        Haptics.vibrate().catch(e => console.error("failed to vibrate", e))
      }
      if (this.$config.sounds) this.beep(minus);
    },
    vibrate() {
      if (window.navigator) {
        if (typeof navigator.vibrate === "function") {
          const success = navigator.vibrate(100);
          return success;
        }
      }
      return false;
    },
    async beep(minus = false) {
      NativeAudio.play({
        assetId: `${minus ? "minus" : "plus"}`,
      });
    },
    load() {
      if (this.$config.mode === "display") return this.$router.push("/display");
      this.index = this.$config.index;

      this.minusIO = this.$config.server.getObjectByName(
        `vinput."$MINUS_${this.index}"`
      );
      this.plusIO = this.$config.server.getObjectByName(
        `vinput."$PLUS_${this.index}"`
      );
    },
    setDialSize() {
      const { height, width } = this.$refs.dialSection.getBoundingClientRect();
      let size = Math.min(height, width);
      this.dialSize = Math.floor(size);
    },
    setBtnSize() {
      const { width, height } = this.$refs.btnSection.getBoundingClientRect();
      let size = Math.min(height / 2, width / 2) * 0.9;
      this.btnSize = Math.floor(size);
    }
  },
  created() {
    if (this.$config.loaded) this.load();
    else {
      let unwatch = this.$watch("$config.loaded", () => {
        unwatch.call(this);
        this.load();
      });
    }
    if (window.navigator) {
      // ensure navigator.vibrate is defined if possible
      navigator.vibrate =
        navigator.vibrate ||
        navigator.webkitVibrate ||
        navigator.mozVibrate ||
        navigator.msVibrate;
    }
  },
  mounted() {
    (async () => {
      await ScreenOrientation.lock({ type: "portrait" });
    })().catch(e => console.error(`Can't lock screen orientation:`, e));
    this.onResize = () => {
      this.setDialSize();
      this.setBtnSize();
    };
    this.onResize();
    window.addEventListener("resize", this.onResize);
    window.addEventListener("orientationchange", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("orientationchange", this.onResize);

    try {
      (async () => {
        await ScreenOrientation.unlock();
      })();
    } catch (e) {
      console.error(`Can't unlock screen orientation:`, e);
    }
  }
};
</script>

<style scoped>
* {
  user-select: none !important;
}

.count-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  grid-template-columns: auto;
  grid-template-rows: 40% 10% 50%;
  overflow: hidden;
}

.count-container>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dial-section {
  height: 40%;
}

.max-section {
  height: 10%;
}

.buttons-section {
  height: 50%;
  display: flex;
  flex: 1 1 auto;
}

.buttons-section>div {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
</style>
