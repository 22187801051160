<template>
  <v-container class="fill-height">
    <v-overlay :value="generatingFile">
      <v-progress-circular
        color="primary"
        indeterminate
        size="50"
      />
    </v-overlay>
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <v-chip
          color="primary"
          @click="showDatePicker = true"
        >{{
            date.toLocaleDateString()
        }}</v-chip>
        <v-dialog
          :value="showDatePicker"
          transition="dialog-bottom-transition"
          width="290px"
          persistent
        >
          <v-card>
            <v-date-picker
              :max="new Date().toISOString()"
              v-model="newDate"
              first-day-of-week="1"
              :locale="locale"
            />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="error"
                @click="cancelNewDate()"
              >
                <v-icon>fa-times</v-icon>
              </v-btn>
              <v-btn
                color="success"
                :disabled="!newDate"
                @click="setNewDate()"
              >
                <v-icon>fa-check</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <div class="d-flex justify-space-between">
          <div class="text-h3">
            <v-icon class="mr-2">fa-users</v-icon>{{ entries === null ? "--" : entries }}
          </div>
          <v-btn
            fab
            class="align-self-end"
            @click="download"
          ><v-icon>fa-download</v-icon></v-btn>
        </div>
      </v-col>
      <v-col>
        <v-card>
          <v-card-text>
            <canvas
              id="occupancy-chart"
              width="1920"
              height="1080"
            ></canvas>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import "chartjs-adapter-moment";

const HOUR = 60 * 60 * 1000;
const DAY = 24 * HOUR;

class ExportData {
  constructor(date, occupancy, entries, timeStep = HOUR) {
    this._occupancy = occupancy;
    this._entries = entries;
    this.timeStep = timeStep;
    const baseTime = date.getTime();
    const oneDayAfter = baseTime + DAY;
    const now = Date.now();
    const endTime = oneDayAfter > now ? now : oneDayAfter;
    const nbParts = Math.ceil((endTime - baseTime) / timeStep);
    this.dates = Array(nbParts).fill();
    for (let i = 0; i < nbParts; i++) {
      this.dates[i] = new Date(baseTime + i * timeStep);
    }
    this.occupancy = this.dates.map(() => 0);
    this.entries = this.dates.map(() => 0);
  }
  async getParts(io) {
    const UNDEFINED_FLOAT = 1.7976931348623158e308;
    const dateStart = this.dates[0];
    const dateEnd = new Date(
      this.dates[this.dates.length - 1].getTime() + this.timeStep
    );
    const now = new Date();
    const events = (
      await io
        .graphEvent(dateStart, dateEnd < now ? dateEnd : now)
        .catch((err) => {
          if (
            err.message ===
            "Cannot read properties of undefined (reading 'data')"
          ) {
            // probably got a 404 cause no event so we can only simply resolve empty array
            return [];
          } else throw err;
        })
    )
      .filter((ev) => (ev.value >= 0 & ev.value !== UNDEFINED_FLOAT))
      .map(({ date, value }) => ({ date: new Date(date), value }))
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    const timeStep = this.timeStep;
    const parts = this.dates.map(() => []);
    let i = 0;
    let tPart = this.dates[i].getTime();
    for (const event of events) {
      const t = event.date.getTime();
      const dt = t - tPart;
      if (dt < 0) continue;
      while (t - tPart > timeStep && i <= this.dates.length) {
        i += 1;
        tPart = this.dates[i].getTime();
      }
      if (i >= this.dates.length) break;
      parts[i].push(event);
    }
    return parts;
  }
  async loadOccupancy() {
    const parts = await this.getParts(this._occupancy);
    const timeStep = this.timeStep;
    let prevValue = 0;
    let prevDate = 0;
    for (let i = 0; i < this.dates.length; i++) {
      prevDate = this.dates[i].getTime();
      const part = parts[i];
      let meanValue = 0;
      for (const ev of part) {
        const t = ev.date.getTime();
        const value = ev.value;
        meanValue += ((t - prevDate) / timeStep) * value;
        prevValue = value;
        prevDate = t;
      }
      meanValue +=
        ((this.dates[i].getTime() + timeStep - prevDate) / timeStep) *
        prevValue;
      this.occupancy[i] = Math.round(meanValue);
    }
    this._occupancyLoaded = true;
  }
  async loadEntries() {
    const parts = await this.getParts(this._entries);
    let lastValue = 0;
    for (let i = 0; i < this.dates.length; i++) {
      const part = parts[i];
      lastValue = part.length ? part[part.length - 1].value : lastValue;
      this.entries[i] = lastValue;
    }
    this._entriesLoaded = true;
  }
  async ensureLoaded() {
    await Promise.all([
      this._entriesLoaded ? Promise.resolve() : this.loadEntries(),
      this._occupancyLoaded ? Promise.resolve() : this.loadOccupancy(),
    ]);
  }
  async toCSV() {
    await this.ensureLoaded();
    const formatDate = (d) => {
      const date = d.toISOString().split("T")[0];
      const time = d.toTimeString().split(" ")[0];
      return `${date} ${time}`;
    };
    const csv =
      "date; occupancy; entries\n" +
      this.dates
        .map((date, i) =>
          [
            formatDate(date),
            this.occupancy[i].toFixed(),
            this.entries[i].toFixed(),
          ].join("; ")
        )
        .join("\n");
    return csv;
  }
}

function download(data, filename, type) {
  var file = new Blob([data], { type: type });
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    // Others
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

export default {
  data() {
    return {
      date: new Date(),
      entries: null,
      newDate: "",
      showDatePicker: false,
      generatingFile: false,
    };
  },
  watch: {
    date() {
      this.loadData();
    },
  },
  methods: {
    async load() {
      this.loadData();
    },
    async loadData() {
      this.entries = null;
      const dateStart = new Date(this.date);
      dateStart.setHours(0, 0, 0, 0);
      const server = this.$config.server;
      const data = new ExportData(
        dateStart,
        server.getObjectByName('voutput.value."$OCCUPANCY"'),
        server.getObjectByName('voutput.value."$IN"'),
        HOUR / 4
      );
      this.data = data;
      await data.ensureLoaded();
      this.entries = Math.max(...data.entries);
      this.chart.data.labels = data.dates;
      this.chart.data.datasets[0] = {
        label: this.$t("site.occupancy"),
        backgroundColor: this.$vuetify.theme.themes.dark.primary,
        data: data.occupancy,
      };
      this.chart.update();
      this.showChart = true;
    },
    async download() {
      if (this.data) {
        this.generatingFile = true;
        const csv = await this.data.toCSV();
        const filename = `${this.data.dates[0].toLocaleDateString()}-${this.$config.site.name
          }.csv`;
        download(csv, filename, "text/csv");
        this.generatingFile = false;
      } else {
        alert(this.$t("alrt.retryLater"));
      }
    },
    cancelNewDate() {
      this.showDatePicker = false;
      this.newDate = "";
    },
    setNewDate() {
      this.date = new Date(this.newDate);
      this.showDatePicker = false;
      this.newDate = "";
    },
  },
  computed: {
    locale() {
      if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
      } else {
        return (
          navigator.userLanguage ||
          navigator.language ||
          navigator.browserLanguage ||
          "en"
        );
      }
    },
  },
  mounted() {
    const ctx = document.getElementById("occupancy-chart").getContext("2d");
    this.chart = new Chart(ctx, {
      type: "bar",
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            type: "time",
            time: {
              unit: "hour",
              unitStepSize: 1,
              displayFormats: {
                hour: "HH",
              },
            },
            ticks: {
              color: "white",
            },
            grid: {
              display: false,
              // color: "white",
            },
            gridLines: {
              offsetGridLines: true,
            },
          },
          y: {
            grid: {
              color: "white",
            },
            beginAtZero: true,
            ticks: {
              color: "white",
            },
          },
        },
      },
    });

    if (this.$config.loaded) this.load();
    else {
      const unwatch = this.$watch("$config.loaded", () => {
        unwatch.call(this);
        this.load();
      });
    }
  },
};
</script>