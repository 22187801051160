const CryptoJS = require("crypto-js");

// const Ajv = require("ajv");
// const ajv = new Ajv({
//   schemas: []
// });
// const _validate = ajv.compile({});

function validate(config) {
  if (!config.site) throw new Error("no site");
  return config;
}
exports.validate = validate;

const KEY = "MathisEstLeMeilleurDevDuMonde";

function encrypt(config) {
  return CryptoJS.AES.encrypt(JSON.stringify(validate(config)), KEY).toString();
}
exports.encrypt = encrypt;
function decrypt(text) {
  const json = CryptoJS.AES.decrypt(text, KEY).toString(CryptoJS.enc.Utf8);
  const config = validate(JSON.parse(json));
  return config;
}
exports.decrypt = decrypt;
