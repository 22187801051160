<template>
  <v-sheet color="transparent" class="fill-height pa-0">
    <div class="display-container">
      <div class="text-center text-h4 text-sm-h2 text-md-h1  font-weight-bold">
        {{ message }}
      </div>
      <div class="icon-section" ref="iconSection">
        <v-icon :size="iconSize">{{ icon }}</v-icon>
      </div>
      <div class="text-center text-h6 text-sm-h4 font-weight-medium text-md-h2">
        {{ $config.loaded ? occupancy : "--" }} /
        {{ $config.loaded ? max : "--" }}
      </div>
      <div class="text-center text-h6 text-sm-h4 text-md-h2 font-weight-medium">
        {{
          $t("display.free", { free: $config.loaded ? max - occupancy : "--" })
        }}
      </div>
    </div>
  </v-sheet>
</template>

<script>
import sleepMixin from "../sleep-mixin";
export default {
  mixins: [sleepMixin],
  data: () => ({
    iconSize: 20
  }),
  watch: {
    color(val) {
      this.setBackgroundColor(val);
    }
  },
  computed: {
    connected() {
      return this.$config.serverConnected;
    },
    message() {
      return this.treshold === 3
        ? this.$t("display.wait")
        : this.$t("display.welcome");
    },
    icon() {
      return this.treshold === 3 ? "fa-male" : "fa-walking";
    },
    max() {
      return this.$config.site.max;
    },
    occupancy() {
      return this.$config.site.occupancy;
    },
    percentage() {
      if (!this.max) return 0;
      return (this.occupancy / this.max) * 100;
    },
    treshold() {
      const {
        $config: {
          site: { tresholds }
        },
        percentage
      } = this;
      for (var i = 0; i < tresholds.length; i++) {
        if (percentage < tresholds[i]) return i;
      }
      return tresholds.length;
    },
    color() {
      if (!this.$config.loaded) return "#BDBDBD";
      if (!this.connected) return "#BDBDBD";
      switch (this.treshold) {
        case 0:
          return "#4CAF50"; // green
        case 1:
          return "#FFC107"; // amber
        case 2:
          return "#FF9800"; // orange
        case 3:
          return "#F44336"; // red
        default:
          return "#BDBDBD";
      }
    }
  },
  methods: {
    setIconSize() {
      const { width, height } = this.$refs.iconSection.getBoundingClientRect();
      this.iconSize = Math.floor(Math.min(width, height) * 0.8);
    },
    setBackgroundColor(val) {
      this.$root.$el.style.backgroundColor = val;
    }
  },
  created() {},
  mounted() {
    this.setBackgroundColor(this.color);

    this.setIconSize();
    this.onResize = () => {
      this.setIconSize();
    };
    window.addEventListener("resize", this.onResize);
    window.addEventListener("orientationchange", this.onResize);

    this.onClick = () => {
      if (this._lastClicked && Date.now() - this._lastClicked < 500) {
        this.$router.push("/preferences");
      } else {
        this._lastClicked = Date.now();
      }
    };
    document.addEventListener("click", this.onClick);
  },
  beforeDestroy() {
    this.setBackgroundColor("");

    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("orientationchange", this.onResize);
    document.removeEventListener("click", this.onClick);
  }
};
</script>

<style scoped>
* {
  user-select: none !important;
}

.display-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.display-container > div {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.display-container > div.font-weight-bold {
  height: 15%;
}
.icon-section {
  height: 70%;
}
.display-container > div.font-weight-medium {
  height: 7.5%;
}
</style>
