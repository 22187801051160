import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

import messages from "./messages";

const detectLocale = require("../../lib/detect-locale");

const i18n = new VueI18n({
  locale: detectLocale() || "en",
  fallbackLocale: "en",
  messages,
  silentTranslationWarn: true
});

export default i18n;
