import Vue from "vue";
import router from "../router";
import i18n from "./i18n";

import { App } from "@capacitor/app";

const EbooServerApi = require("@eboo/ebooserver-api/lib");
const srvApi = new EbooServerApi();

const { Preferences } = require("@capacitor/preferences");

function reactiveMerge(target, obj) {
  Object.keys(obj).forEach((prop) => {
    if (Object.prototype.toString.call(obj[prop]) === "[object Object]") {
      if (!(prop in target)) Vue.set(target, prop, {});
      reactiveMerge(target[prop], obj[prop]);
    } else {
      Vue.set(target, prop, obj[prop]);
    }
  });
}

const { encrypt, decrypt } = require("../lib/config");

let allowConfigLoad;
const waitUntilLoadingConfigIsAllowed = new Promise((resolve) => {
  allowConfigLoad = resolve;
});

class Config {
  constructor() {
    this.loaded = false;

    this.serverConnected = false;
    setInterval(() => {
      if (this.server && this.server._streams.size) {
        const stream = this.server._streams.values().next().value;
        this.serverConnected = !!stream.req && stream.req.connected;
      } else this.serverConnected = false;
    }, 100);

    // site
    this.clearSite(false);

    // preferences
    this.language = null;
    this.vibrations = true;
    this.sounds = true;
    this.verticalButtons = false;
    this.reverseButtons = false;
  }
  async load(opts = {}, save = true) {
    // avoid race condition
    if (this._loading) await this._loading;

    const initSite = async () => {
      {
        const site = this.site;
        if (site._timestamp) {
          if (site.ttl > 0) {
            const expiryDate = new Date(site._timestamp + 60 * 1000 * site.ttl);
            const removeSite = async () => {
              console.debug("access expired");
              this.clearSite();
              alert(i18n.t("alert.accessExpired"));
              if (save) await this.save();
            };
            if (expiryDate.getTime() - (Date.now() + 60 * 1000) < 0) {
              // site already expired or will expire in less than a minute
              return await removeSite();
            } else {
              console.debug(`access will expire on ${expiryDate}`);
              const getSiteHash = ({ server, ttl }) =>
                JSON.stringify({
                  mode: this.mode,
                  index: this.index,
                  server,
                  ttl,
                });
              const siteHash = getSiteHash(site);
              setTimeout(async () => {
                if (getSiteHash(this.site) === siteHash) await removeSite();
              }, expiryDate.getTime() - Date.now());
            }
          }
        }

        await this.loadServer();
      }
    };

    try {
      this._loading = (async () => {
        console.debug("loading config", opts);
        this.clearSite(false);
        reactiveMerge(this, opts);
        if (this.language) i18n.locale = this.language;
        if (this.hasSite) await initSite();
        this.loaded = this.hasSite;
      })();
      await this._loading;
      delete this._loading;
    } catch (e) {
      console.error("Failed to load config:", opts, e);
      alert(i18n.t("alert.configLoadFailed"));
    }
    if (save) await this.save();
  }
  async loadServer() {
    const site = this.site;
    this.server = srvApi.registerServer(site.server);
    await this.server.load();

    await new Promise((resolve) => {
      // sync
      let nbLoaded = 0;

      this.server
        .getObjectByName('voutput.value."$OCCUPANCY"')
        .stream({ keepalive: 2 })
        .once("data", () => {
          nbLoaded++;
          if (nbLoaded === 2) resolve();
        })
        .on("data", (data) => {
          if (data.body.defined) site.occupancy = data.body.value;
        });
      this.server
        .getObjectByName('vinput.value."$MAX"')
        .stream({ keepalive: 2 })
        .once("data", () => {
          nbLoaded++;
          if (nbLoaded === 2) resolve();
        })
        .on("data", (data) => {
          if (data.body.defined) site.max = data.body.value;
        });
    });
  }
  destroyServer() {
    if (this.server) {
      this.server.destroy();
      srvApi.servers.delete(this.server);
    }
    this.server = null;
  }
  async reloadServer() {
    this.destroyServer();
    await this.loadServer();
  }
  confirmLoad(config) {
    return confirm(
      i18n.t("confirm.configLoad", {
        siteName: config.site ? config.site.name : null,
        mode: config.mode,
        index: config.index,
      })
    );
  }
  get hasSite() {
    return !!this.site.name;
  }
  clearSite(save = true) {
    this.destroyServer();
    reactiveMerge(this, {
      mode: null, //"count", // count, admin, display
      index: 0,
      site: {
        name: "",
        ttl: 0,
        _timestamp: null, // used to know when it expires
        tresholds: [70, 90, 100],
        server: {},
        occupancy: 0,
        max: 0,
      },
    });
    this.loaded = false;
    if (save) console.debug("site cleared");
    if (save) return this.save();
  }
  export() {
    // profile
    const { mode, index, site } = this;
    // preferences
    const { language, vibrations, sounds, verticalButtons, reverseButtons } =
      this;
    return JSON.parse(
      JSON.stringify({
        mode,
        index,
        site,
        language,
        vibrations,
        sounds,
        verticalButtons,
        reverseButtons,
      })
    );
  }
  async save() {
    // does nothing by default
  }
  // TODO: remove ???
  async loadDemo(name) {
    const config = require("../../configs/json/demo-" + name + ".json");
    await this.load(config);
  }
  async loadURL(_url) {
    await waitUntilLoadingConfigIsAllowed;
    const url = new URL(_url);
    console.debug(`loading url: ${url}`);
    const siteParam = url.searchParams.get("site");
    if (siteParam) {
      const encrypted = decodeURIComponent(siteParam);
      try {
        const _config = decrypt(encrypted);
        if (this.confirmLoad(_config)) {
          await this.load(_config);
          if (router.currentRoute.path !== this.getMainPage()) router.push("/");
        }
      } catch (e) {
        console.error("invalid config", e);
        alert(i18n.t("alert.invalidConfig"));
      }
    }
  }
  getMainPage() {
    if (!this.mode) {
      // by default, ask to load config
      return "/load";
    } else if (this.mode === "count" || this.mode === "admin") return "/count";
    else if (this.mode === "display") return "/display";
    else if (this.mode === "stats") return "/stats";
    else throw new Error(`unsupported mode ${this.mode}`);
  }
}

Vue.use({
  install(Vue) {
    const config = Vue.observable(new Config());
    Vue.set(Vue.prototype, "$config", config);

    let saveTimeout;
    function saveConfig() {
      clearTimeout(saveTimeout);
      saveTimeout = setTimeout(() => {
        config.save();
      }, 1000);
    }

    // router redirection
    router.addRoutes([
      {
        path: "/",
        redirect: () => config.getMainPage(),
      },
    ]);

    // watcher instance
    const watcherInstance = new Vue();
    [
      ...Object.keys(config.export()).map((prop) => [
        `$config.${prop}`,
        saveConfig,
      ]),
      [
        "$config.serverConnected",
        (val) => {
          console.debug(`server ${val ? "" : "dis"}connected`);
        },
      ],
      [
        "$config.language",
        (val) => {
          console.debug(`language changed ${val}`);
          if (val) i18n.locale = val;
        },
      ],
    ].forEach(([key, fn]) => {
      watcherInstance.$watch(key, fn);
    });

    // deeplinks
    App.addListener('appUrlOpen', function (event) {
      // Example url: https://beerswift.app/tabs/tabs2
      // slug = /tabs/tabs2
      const slug = event.url.split('.app').pop();
    
      // We only push to the route if there is a slug present
      if (slug) {
        config.loadURL(slug);
      }
    });

    async function loadConfig() {
      const { value: encrypted } = await Preferences.get({ key: "config" });
      if (encrypted) {
        await config.load(decrypt(encrypted), false);
        console.debug("config loaded");
        allowConfigLoad();
      } else {
        allowConfigLoad();
      }
    }
    Config.prototype.save = async function () {
      try {
        const config = this.export();
        const encrypted = encrypt(config);
        await Preferences.set({ key: "config", value: encrypted });
        console.debug("config saved");
      } catch (e) {
        console.error("Failed to save config:", config, e);
        alert(i18n.t("alert.configSaveFailed"));
      }
    };

    const loadPromise = (async () => {
      await loadConfig();
      console.debug("app loaded");
    })();

    Vue.prototype.waitUntilLoaded = async () => {
      await loadPromise;
    };
  },
});
