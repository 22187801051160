<template>
  <v-container
    fluid
    class="fill-height pa-0"
  >
    <div class="scanner-container">
      <div class="scanner-zone"></div>
    </div>
  </v-container>
</template>

<script>
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { scannerIsAvailable, didUserGrantPermission } from '../lib/scanner';
const prepareScan = async () => {
  BarcodeScanner.prepare();
};

const startScan = async () => {
  const result = await BarcodeScanner.startScan();
  if (result.hasContent) {
    return result.content
  }
};

const stopScan = () => {
  BarcodeScanner.stopScan();
};

const decryptConfig = require("../lib/config").decrypt;
export default {
  methods: {
    prepare() {
      return prepareScan()
    },
    scan() {
      startScan().catch(err => {
        // an error occurred, or the scan was canceled (error code `6`)
        if (err.name !== "SCAN_CANCELED") {
          console.error("got error while scanning", err);
          this.scan();
        }
      }).then(text => {
        // The scan completed
        try {
          try {
            const config = decryptConfig(text);
            const confirmed = this.$config.confirmLoad(config);
            if (confirmed) {
              config.site._timestamp = Date.now();
              this.$config.load(config);
              // don't wait for config load to redirect
              this.$router.push("/");
            } else this.scan();
            return;
          } catch (e) {
            alert(this.$t("alert.invalidConfig"));
            this.scan();
            return;
          }
        } catch (e) {
          this.scan();
          return;
        }
      })
    },
    cancelScan() {
      stopScan()
    },
    show() {
      this._backgroundSave = this.$root.$el.style.background;
      this.$root.$el.style.background = "transparent";
    },
    hide() {
      this.$root.$el.style.background = this._backgroundSave || "";
    }
  },
  async mounted() {
    if (!scannerIsAvailable()) {
      alert(this.$t("alert.qrScannerNotAvailable"));
      this.$router.push("/");
    } else if (!(await didUserGrantPermission())) {
      alert(this.$t("alert.qrScannerNoPermission"));
      this.$router.push("/");
    } else {
      try {
        await this.prepare();
        this.show();
        this.scan()
      } catch (e) {
        console.error("qr scanner error", e);
        alert(this.$t("alert.error"));
        this.$router.push("/");
      }
    }
  },
  beforeDestroy() {
    if (scannerIsAvailable()) {
      this.cancelScan();
      this.hide();
      stopScan()
    }
  }
};
</script>

<style scoped>
.scanner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scanner-container .scanner-zone {
  height: min(80vw, 80vh);
  width: min(80vw, 80vh);
  border: 5px solid black;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
}
</style>
