<template>
  <v-container class="fill-height">
    <v-row class="justify-center">
      <v-col
        sm="8"
        class="text-center"
      >
        <v-progress-circular
          v-if="loading"
          indeterminate
        />
        <v-card v-else>
          <v-card-title class="justify-center">
            {{ $t("site.pleaseLoad") }}
          </v-card-title>
          <v-card-text>
            <v-btn
              v-if="scannerIsAvailable"
              block
              to="/scanner"
              class="mb-2"
            >
              <v-icon>fa-qrcode</v-icon>
            </v-btn>
            <v-text-field
              v-model="url"
              label="URL"
              append-outer-icon="fa-check"
              @click:append-outer="loadURL()"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { scannerIsAvailable } from '../lib/scanner';
export default {
  data() {
    return {
      scannerIsAvailable: scannerIsAvailable(),
      url: "",
      loading: true,
    };
  },
  methods: {
    async loadURL(url = "") {
      this.loading = true;
      await this.$config.loadURL(url || this.url);
      this.loading = false;
    },
  },
  created() {
    if (this.$route.query.site) {
      this.loadURL("eboocount:/" + this.$route.fullPath);
    } else this.loading = false;
    console.log(scannerIsAvailable())
  },
};
</script>
