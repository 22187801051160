import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import "typeface-roboto/index.css";
import "@fortawesome/fontawesome-free/css/all.css";

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#4CAF50",
        success: "#4CAF50"
      }
    }
  },
  icons: {
    iconfont: "fa"
  }
});
