<template>
  <v-app>
    <v-app-bar
      app
      @click="move"
      v-if="showBar"
    >
      <v-spacer />
      <v-toolbar-title>{{
        $config.site.name || $t("site.noSite")
      }}</v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <v-main v-if="ready">
      <router-view></router-view>
    </v-main>
    <v-overlay
      v-else
      absolute
      opacity="1"
      :value="!ready"
    >
      <v-progress-circular
        indeterminate
        size="200"
      />
    </v-overlay>
    <v-footer
      dark
      padless
      v-if="showFooter"
    >
      <v-card
        class="flex"
        flat
        tile
      >
        <v-card-text class="py-2 white--text text-center">
          {{ $t("access", { index: $config.index }) }}
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ready: false,
  }),
  computed: {
    showBar() {
      return (
        this.$route.path !== "/load" &&
        (this.$config.mode !== "display" || this.$route.path === "/preferences")
      );
    },
    showFooter() {
      return !!this.$config.index && this.$config.mode !== "display";
    },
  },
  methods: {
    move() {
      if (this.$route.path !== "/preferences")
        this.$router.push("/preferences");
      else this.$router.push("/");
    },
  },
  async created() {
    document.addEventListener(
      "backbutton",
      () => {
        console.debug("back button pressed");
        this.move();
      },
      false
    );
    await this.waitUntilLoaded()
    this.ready = true
    if (this.$route.path !== this.$config.getMainPage()) {
      this.$router.push(this.$config.getMainPage());
    }
  },
};
</script>

<style>
.v-app-bar {
  height: calc(56px + env(safe-area-inset-top)) !important;
  padding-top: env(safe-area-inset-top) !important;
}

.v-main {
  padding-top: calc(56px + env(safe-area-inset-top)) !important;
}

.v-main:only-child {
  padding-top: env(safe-area-inset-top) !important;
}

.v-footer>.v-card {
  padding-bottom: env(safe-area-inset-top) !important;
}

.no-focus:focus:before {
  opacity: 0 !important;
}
</style>
