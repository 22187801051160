<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12">
        <v-subheader>
          {{ $t("preferences.header") }}
        </v-subheader>
        <v-divider />
        <v-autocomplete
          class="mt-4 mb-2"
          :label="$t('preferences.language')"
          :items="locales"
          v-model="$config.language"
          hide-details
        />
        <v-switch
          :label="$t('preferences.vibrations')"
          v-model="$config.vibrations"
        ></v-switch>
        <v-switch
          :label="$t('preferences.sounds')"
          v-model="$config.sounds"
        ></v-switch>
        <v-switch
          :label="$t('preferences.verticalButtons')"
          v-model="$config.verticalButtons"
        ></v-switch>
        <v-switch
          :label="$t('preferences.reverseButtons')"
          v-model="$config.reverseButtons"
        ></v-switch>
      </v-col>
      <v-col cols="12">
        <v-subheader>
          {{ $t("site.header") }}
        </v-subheader>
        <v-divider />
        <v-text-field
          solo
          readonly
          hide-details
          :value="$config.hasSite ? $config.site.name : $t('site.noSite')"
          class="my-4"
        >
          <template v-slot:append>
            <v-btn v-if="$config.hasSite" icon @click="clearSite">
              <v-icon small>fa-times</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <v-btn v-if="!$config.hasSite" block to="/scanner" class="mb-2"
          ><v-icon>fa-qrcode</v-icon></v-btn
        >
        <div v-if="!$config.hasSite && showDemo">
          <div>demo site :</div>
          <v-chip
            v-for="(mode, i) in ['count', 'display', 'admin']"
            :key="i"
            @click="useDemoSite(mode)"
            class="ma-2"
          >
            {{ mode }}
          </v-chip>
        </div>
        <template v-if="$config.mode === 'admin'">
          <v-text-field
            type="number"
            :min="0"
            v-model.number="siteMax"
            :label="$t('site.max')"
            @focusout="setSiteMax"
          >
            <template v-slot:append>
              <v-btn icon @click="setSiteMax">
                <v-icon :class="{ 'fa-spin': settingSiteMax }" small>{{
                  settingSiteMax ? "fa-spinner" : "fa-check"
                }}</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <v-text-field
            type="number"
            :min="0"
            v-model.number="siteOccupancy"
            readonly
            :label="$t('site.occupancy')"
          >
            <template v-slot:append>
              <v-btn icon @click="resetSiteOccupancy">
                <v-icon small>fa-undo</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </template>
        <v-divider class="mt-4" />
        <div class="caption my-2">v{{ version }}</div>
        <div class="caption my-2">{{ $t("copyright") }}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const detectLocale = require("../lib/detect-locale");
const emojiFlags = require("emoji-flags");

const VERSION = require("../../package.json").version;

function getCountryFromLocaleString(locale) {
  const code = locale.split(/[_-]/)[1];
  if (code) return code.toUpperCase();
}

function getFlag(locale) {
  function getEmoji(countryCode) {
    try {
      const country = emojiFlags.countryCode(countryCode);
      if (country) return country.emoji;
    } catch (e) {
      return;
    }
  }
  switch (locale) {
    case "en":
      return getEmoji("GB");
    case "fr":
      return getEmoji("FR");
    default:
      return getEmoji(getCountryFromLocaleString(locale));
  }
}

export default {
  data: () => ({
    siteMax: 0,
    siteOccupancy: 0,
    settingSiteMax: false,
    demoEasterEgg: false
  }),
  watch: {
    siteMax(val) {
      if (typeof val !== "number") this.siteMax = 0;
    },
    "$config.site.max"(val) {
      this.siteMax = val;
    },
    "$config.site.occupancy"(val) {
      this.siteOccupancy = val;
    }
    // demo easter egg
    // "$config.sounds"() {
    //   const now = Date.now();
    //   if (this._demoLast && now - this._demoLast < 500) {
    //     this._demoLast = now;
    //     this._demoN++;
    //   } else {
    //     this._demoLast = now;
    //     this._demoN = 0;
    //   }
    //   if (!this.demoEasterEgg && this._demoN >= 10) {
    //     console.debug("enabling demo easter egg");
    //     this.demoEasterEgg = true;
    //   }
    // }
  },
  computed: {
    locales() {
      const autoLocale = detectLocale();
      const autoFlag = getFlag(autoLocale);
      const locales = [
        {
          text: (autoFlag ? autoFlag + " " : "") + "auto",
          value: null
        }
      ];
      locales.push(
        ...this.$root.$i18n.availableLocales.map(locale => {
          const flag = getFlag(locale);
          return {
            text: (flag ? flag + " " : "") + locale,
            value: locale
          };
        })
      );
      return locales;
    },
    showDemo() {
      return this.demoEasterEgg || process.env.NODE_ENV === "development";
    },
    version() {
      return VERSION;
    }
  },
  methods: {
    clearSite() {
      this.$config.clearSite();
    },
    async setSiteMax() {
      if (this.siteMax !== this.$config.site.max) {
        this.settingSiteMax = true;
        if (document.activeElement) document.activeElement.blur();
        await this.$config.server
          .getObjectByName('vinput.value."$MAX"')
          .writeIO(this.siteMax);
        this.settingSiteMax = false;
        setTimeout(() => {
          alert(this.$t("alert.maxUpdated"));
        }, 50);
      }
    },
    async resetSiteOccupancy() {
      const confirmed = confirm(this.$t("confirm.resetOccupancy"));
      if (confirmed) {
        await this.$config.server
          .getObjectByName('vinput."$RESET"')
          .writeIO(true);
      }
    },
    initAdminProps() {
      const site = this.$config.site;
      this.siteMax = site.max || 0;
      this.siteOccupancy = site.occupancy || 0;
    },
    async useDemoSite(mode = "count") {
      await this.$config.loadDemo(mode);
    }
  },
  created() {
    if (this.$config.mode === "admin") this.initAdminProps();
  }
};
</script>

<style>
:not(input) {
  user-select: none;
}
</style>
