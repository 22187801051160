import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Load from "./views/load";
import Count from "./views/count";
import Display from "./views/display";
import Scanner from "./views/scanner";
import Preferences from "./views/preferences";
import Stats from "./views/stats";

const routes = [
  { path: "/load", component: Load },
  { path: "/count", component: Count },
  { path: "/display", component: Display },
  { path: "/scanner", component: Scanner },
  { path: "/preferences", component: Preferences },
  { path: "/stats", component: Stats },
];

const router = new VueRouter({
  routes,
});

export default router;
