import { KeepAwake } from "@capacitor-community/keep-awake";

let IS_SUPPORTED = null;

async function isSupported() {
  if (IS_SUPPORTED === null) {
    const result = await KeepAwake.isSupported();
    IS_SUPPORTED = result.isSupported;
    return result.isSupported;
  } else {
    return IS_SUPPORTED;
  }
}

export default {
  methods: {
    async keepAwake() {
      if (await isSupported()) {
        await KeepAwake.keepAwake();
      }
    },
    async allowSleepAgain() {
      if (await isSupported()) {
        await KeepAwake.allowSleep();
      }
    },
  },
  mounted() {
    this.keepAwake();
  },
  beforeDestroy() {
    this.allowSleepAgain();
  },
};
