const messages = {
  preferences: {
    header: {
      en: "Preferences",
      fr: "Préférences",
    },
    language: {
      en: "Language",
      fr: "Langue",
    },
    vibrations: {
      en: "Vibrations",
      fr: "Vibrations",
    },
    sounds: {
      en: "Sounds",
      fr: "Sons",
    },
    reverseButtons: {
      en: "Reverse Buttons",
      fr: "Boutons inversés",
    },
    verticalButtons: {
      en: "Vertical Buttons",
      fr: "Boutons verticaux",
    },
  },
  access: {
    en: "access: %{index}",
    fr: "accès: %{index}",
  },
  site: {
    pleaseLoad: {
      en: "Please load a site",
      fr: "Merci de charger un site",
    },
    header: {
      en: "Site",
      fr: "Site",
    },
    noSite: {
      en: "no site",
      fr: "pas de site",
    },
    max: {
      en: "Max",
      fr: "Max",
    },
    occupancy: {
      en: "Occupancy",
      fr: "Présence",
    },
  },
  display: {
    welcome: {
      en: "Welcome",
      fr: "Entrez",
    },
    wait: {
      en: "Please Wait",
      fr: "Veuillez patienter",
    },
    free: {
      en: "free %{free}",
      fr: "libre %{free}",
    },
  },
  alert: {
    accessExpired: {
      en: "Access Expired",
      fr: "Accès expiré",
    },
    configLoadFailed: {
      en: "Failed to load configuration",
      fr: "Impossible de charger la configuration",
    },
    configSaveFailed: {
      en: "Failed to save configuration",
      fr: "Impossible de sauvegarder la configuration",
    },
    invalidConfig: {
      en: "Invalid Configuration",
      fr: "Configuration invalide",
    },
    maxUpdated: {
      en: "Maximum Capacity Updated",
      fr: "Capacité maximum mise à jour",
    },
    qrScannerNotAvailable: {
      en: "QR scanner is not available on this device",
      fr: "Lecteur de code QR indisponible",
    },
    qrScannerNoPermission: {
      en: "QR scanner requires camera permission, please grant this app needed permissions",
      fr: "Le lecteur de code QR a besoin de la caméra, merci d'accordeer à cette application les permissions nécessaires",
    },
    error: {
      en: "An Error Occured",
      fr: "Erreur",
    },
    retryLater: {
      en: "Please retry later",
      fr: "Veuilliez réessayer plus tard",
    },
  },
  confirm: {
    configLoad: {
      en: (ctx) =>
        `site: ${ctx.named("siteName")}\nmode: ${ctx.named("mode")}${
          ctx.named("index") ? "\naccess: " + ctx.named("index") : ""
        }`,
      fr: (ctx) =>
        `site: ${ctx.named("siteName")}\nmode: ${ctx.named("mode")}${
          ctx.named("index") ? "\naccès: " + ctx.named("index") : ""
        }`,
    },
    resetOccupancy: {
      en: "Do you really want to reset occupancy ?",
      fr: "Voulez vous vraiment réinitialiser le nombre de présent ?",
    },
  },
  copyright: {
    en: () =>
      `Copyright © ${new Date().getFullYear()} eboo solutions. All rights reserved.`,
    fr: () =>
      `Copyright © ${new Date().getFullYear()} eboo solutions. Tous droits réservés.`,
  },
};

function formatMessagesObject(obj) {
  const paths = [];
  const walk = (obj, path = []) => {
    if (typeof obj === "object") {
      Object.keys(obj).forEach((key) => {
        walk(obj[key], [...path, key]);
      });
    } else {
      paths.push(path);
    }
  };
  walk(obj);
  const get = (obj, path = []) => {
    let val = obj;
    const revPath = [...path].reverse();
    while (revPath.length > 0) {
      val = val[revPath.pop()];
    }
    return val;
  };
  const set = (obj, path = [], val) => {
    if (path.length < 1) throw new Error("path contain more than one key");
    let target = obj;
    const revPath = [...path].reverse();
    while (revPath.length > 1) {
      const key = revPath.pop();
      if (!(key in target)) target[key] = {};
      target = target[key];
    }
    target[revPath[0]] = val;
  };
  const messages = {};
  paths.forEach((_path) => {
    const path = [..._path];
    const lang = path.pop();
    set(messages, [lang, ...path], get(obj, _path));
  });
  return messages;
}

export default formatMessagesObject(messages);
